body{
  color: $font-color;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 62.5%;
}

header{
  $image-size: 7.0em;

  background-color: $header-background;
  border-bottom: lighten($header-background, 30%);
  color: $header-color;
  min-height: $image-size + 2.0em;
  padding: 1.0em ($image-size + 3.0em) 1.0em 1.0em;
  position: relative;

  a{
    color: $header-color;
  }
  
  .avatars{
    display: none;
    position: absolute;
    right: 5px;
    
    li{
      display: inline-block;

      img{
	border-radius: 50%;
	height: $image-size;
	margin: 0.25em;
	width: $image-size;
      }
    }

    @media (min-width: 800px) {
      display: block;
    }
  }

  .contact-info{
    font-size: 1.4em;

    $seperator: ' / ';

    #phone-self{
      &::before{
        content: $seperator;
      }
    }

    .address{

      .street, .town{
	      &::after{
	        content: ',';
	      }
      }
      
      &::before{
	      content: $seperator;
      }
    }
    
    .url-self{
      display: none;
    }    
  }
}

h1{
  color: $font-color-emphasis;
  font: normal 3.35em 'Abril Fatface', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

section.main{
  font-size: 1.6em;
  padding: 0 1.0em 1.0em 1.0em;

  a{
    color: $header-background;
    text-decoration: underline;
  }
  
  h1{
    font-size: 2.0em;
    margin: 1.0em 0 0.25em 0;
  }

  p{
    margin: 1.0em 0;
  }

  h2{

    font-weight: bold;
    
    & + p{
      margin-top: 0;
    }
  }
}

ul.technologies{
  @include delimited-list(',',' & ')
}
