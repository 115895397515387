@mixin clearfix{
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin inline-list {
  list-style-type: none;
  &, & li {
    margin: 0;
    padding: 0;
    display: inline;
  }
}

@mixin delimited-list($separator: ",", $last: ",") {
  @include inline-list;
  li {
    
    &::after {
      content: $separator;
    }
    
    &:nth-last-child(2) {
      &::after{
	content: $last ;
      }
    }
    
    &:last-child {
      &::after{
	content: '';
      }
    }
  }
}

%a-nonymous{
  color: inherit;
  text-decoration: none;

  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}

a{
  @extend %a-nonymous;
}